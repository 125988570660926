import { render, staticRenderFns } from "./developers-wxwork-doc.vue?vue&type=template&id=068029b8"
import script from "./developers-wxwork-doc.vue?vue&type=script&lang=js"
export * from "./developers-wxwork-doc.vue?vue&type=script&lang=js"
import style0 from "./developers-wxwork-doc.vue?vue&type=style&index=0&id=068029b8&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports