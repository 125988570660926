<template>
	<div class="doc-container">
		<div class="doc-header">
			<span class="title">基础设置｜企业微信授权</span>
		</div>
		<div class="doc-scroll">
			<div class="doc-main">
				<div class="doc-meau">
					<div class="doc-meau-contnet">
						<div class="doc-meau-directory">
							<div class="doc-directory-title">目录</div>
							<div class="doc-directory-container">
								<ul class="doc-directory-list">
									<li :class="['toc-entry', 'heading-1',focusIndex === 0 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section1')">一、企业信息设置</div>
									</li>
									<li :class="['toc-entry','heading-1',focusIndex === 1 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section2')">二、企微通讯录设置</div>
									</li>
									<li :class="['toc-entry','heading-1',focusIndex === 2 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section3')">三、企微客户信息设置</div>
									</li>
									<li :class="['toc-entry', 'heading-1',levelTwoIsCollapsed ? 'is-collapsed':'',focusIndex === 3 ? 'is-active': '']">
										<span class="icon-collapsed" @click="onSwtichTwoTable"><svg id="图层_1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 10 10" style="enable-background:new 0 0 10 10" xml:space="preserve"> <g id="Artboard"> <polygon id="Triangle-3-Copy-13" style="fill:#a5a5a5" points="8,5.5 3,9 3,2 " /> </g> </svg> </span>
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section4')">四、企微应用设置</div>
									</li>
									<li v-show="!levelTwoIsCollapsed" :class="['toc-entry','heading-2',focusIndex === 4 ? 'is-active': '']">
										<div class="dic-text" @click="onScrollToRef('section5')">1、自建应用教程</div>
									</li>
									<li v-show="!levelTwoIsCollapsed" :class="['toc-entry','heading-2',focusIndex === 5 ? 'is-active': '']">
										<div class="dic-text" @click="onScrollToRef('section6')">2、系统对接应用授权</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<h3 class="doc-title">基础设置｜企业微信授权</h3>
				<div class="doc-document">
					<h5 ref="section1" class="main-title">一、企业信息设置</h5>
					<h7 class="small-title">
						<b class="small-title-word">1.</b>
						<p class="small-title-desc">登录<a href="https://work.weixin.qq.com/login" target="_blank" rel="noopener noreferrer nofollow">【企业微信后台】</a>，打开【我的企业】-【企业信息】模块中找到「企业简称」项，复制简称粘贴到系统【企业名称】输入框内；</p>
					</h7>
					<h7 class="small-title">
						<b class="small-title-word">2.</b>
						<p class="small-title-desc">在【企业信息】模块找到「企业ID」项，复制并粘贴到系统【企业ID】输入框内；</p>
					</h7>
					<p class="mod-img">
						<img class="help-imgs" alt="" :src="imgs[0]" @click="onprevImg(0)">
					</p>
					<h6 ref="section2" class="main-title" style="margin-top:20px;">二、企微通讯录设置</h6>
					<h7 class="small-title">
						<b class="small-title-word">1.</b>
						<p class="small-title-desc">打开【管理工具】栏目，进入【通讯录同步】项内；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[1]" @click="onprevImg(1)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word">2.</b>
						<p class="small-title-desc">进入内页后，找到【Secret】项，点击【查看】按钮，复制并粘贴到系统【通讯录Secret】输入框内；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[2]" @click="onprevImg(2)">
					</p>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[3]" @click="onprevImg(3)">
					</p>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[4]" @click="onprevImg(4)">
					</p>
					<h6 ref="section3" class="main-title" style="margin-top:20px;">三、企微客户信息设置</h6>
					<h7 class="small-title">
						<b class="small-title-word">1.</b>
						<p class="small-title-desc">打开【客户联系】-【客户】栏目，点开【API】按钮，点击【查看】按钮复制「Secret」值到系统对应输入框内；</p>
					</h7>
					<h7 class="small-title">
						<b class="small-title-word">2.</b>
						<p class="small-title-desc">再点开企业微信栏目下的【接收事件服务器】项，进入内页；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[5]" @click="onprevImg(5)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word">3.</b>
						<p class="small-title-desc">复制系统中的「回调地址URL」，粘贴到「URL」输入框内；</p>
					</h7>
					<h7 class="small-title">
						<b class="small-title-word">4.</b>
						<p class="small-title-desc">再点击【Token】和【EncodingAESKey】的「随机获取」按钮，获取随机值，再分别复制这两组随机值，粘贴到系统中的相应位置（保持企微后台和系统中的数值一致即可），保存即可；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[6]" @click="onprevImg(6)">
					</p>
					<p style="color:rgb(232, 50, 60);font-weight: bold;">特别注意：完成上述的配置后，还需要在企业微信后台分配给成员「客户和客户群联系权限」，否则无法执行群发任务，操作步骤如下：</p>
					<h7 class="small-title">
						<b class="small-title-word">5.</b>
						<p class="small-title-desc">在企业微信后台【客户联系】-【权限配置】栏目，点击「企业范围」的添加按钮，将有分配权限的部门/成员选中，最后保存即可。</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[7]" @click="onprevImg(7)">
					</p>
					<h6 ref="section4" class="main-title" style="margin-top:20px;">四、企微应用设置</h6>
					<h7 class="sub-title blod">
						<b class="small-title-word">1、</b>
						<p ref="section5" class="small-title-desc">自建应用教程</p>
					</h7>
					<h7 class="small-title">
						<b class="small-title-word" style="width: 90px;text-indent: 30px;font-weight:blod;letter-spacing: 3px;">a.</b>
						<p class="small-title-desc" style="padding-left: 50px;">打开企业微信后台，找到【应用管理】栏目，在【应用】模块内找到【自建】一栏下方点击【创建应用】，进入创建内部应用页面，上传应用所需的LOGO、应用名称、应用介绍、可见成员范围，填写完毕再点击创建应用；</p>
					</h7>
					<p class="mod-img">
						<img class="help-imgs" alt="" :src="imgs[8]" @click="onprevImg(8)">
					</p>
					<p class="mod-img" style="margin-top:20px;">
						<img class="help-imgs" alt="" :src="imgs[9]" @click="onprevImg(9)">
					</p>
					<p class="mod-tips" style="margin-bottom:20px;">可点击<a :href="imgs[10]" download="logo.png" target="_blank" rel="noopener noreferrer nofollow">下载</a>Logo素材进行使用<img width="256" height="256" :src="imgs[10]" alt="" style="cursor: zoom-in;" @click="onprevImg(10)"></p>
					<h7 class="sub-title blod">
						<b class="small-title-word">2、</b>
						<p ref="section6" class="small-title-desc">系统对接应用授权</p>
					</h7>
					<h7 class="small-title">
						<b class="small-title-word" style="width: 90px;text-indent: 30px;font-weight:blod;letter-spacing: 3px;">a.</b>
						<p class="small-title-desc" style="padding-left: 50px;">创建完成后，打开应用主页，复制本应用的【应用名称】、【AgentId】、【Secret】（Secret值需到企业微信中查看），粘贴到系统内相应位置；</p>
					</h7>
					<p class="mod-img">
						<img class="help-imgs" alt="" :src="imgs[11]" @click="onprevImg(11)">
					</p>
					<p class="mod-img" style="margin-top:20px;margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[12]" @click="onprevImg(12)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word" style="width: 90px;text-indent: 30px;font-weight:blod;letter-spacing: 3px;">a.</b>
						<p class="small-title-desc" style="padding-left: 50px;">在系统中，复制【可信域名】项的地址，再回到企业微信应用主页（页面往下滑），找到并点开【开发者接口】-【网页授权及JS- SD K】项，将地址粘贴在可信域名输入框内，再点击【申请校验域名】的文字按钮；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[13]" @click="onprevImg(13)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word" style="width: 90px;text-indent: 30px;font-weight:blod;letter-spacing: 3px;">a.</b>
						<p class="small-title-desc" style="padding-left: 50px;">此时弹窗页会向下展开，下载【txt文件】，勾选选项上并保存，再回到系统上传刚下载的txt文件；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[14]" @click="onprevImg(14)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word" style="width: 90px;text-indent: 30px;font-weight:blod;letter-spacing: 3px;">a.</b>
						<p class="small-title-desc" style="padding-left: 50px;">回到企业微信应用主页，找到【开发者接口】-【企业微信授权登录】项，点开进入设置页，将可信域名粘贴在【设置授权回调域】项的输入框内，保存即完成；</p>
					</h7>
					<p class="mod-img">
						<img class="help-imgs" alt="" :src="imgs[15]" @click="onprevImg(15)">
					</p>
					<p class="mod-img" style="margin-top:20px;">
						<img class="help-imgs" alt="" :src="imgs[16]" @click="onprevImg(16)">
					</p>
					<p class="mod-img" style="margin-top:20px;margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[17]" @click="onprevImg(17)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word" style="width: 90px;text-indent: 30px;font-weight:blod;letter-spacing: 3px;">a.</b>
						<p class="small-title-desc" style="padding-left: 50px;">完成上述步骤即表示已授权成功，接下来在系统中复制【全员营销地址】，回到企业微信应用主页，此处有2类入口设置：</p>
					</h7>
					<h7 class="small-title">
						<b class="small-title-word word-mark" style="width: 90px;text-indent: 66px;top:-3px;" />
						<p class="small-title-desc" style="padding-left: 80px;">如希望在企微中点击应用图标，直接进入全员营销工作台，则设置如下：找到【应用主页】项，点开并选择【网页】项，粘贴全员营销地址至网页地址输入框内，保存即完成配置；再登录企业微信客户端，找到应用，即可马上使用；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px;">
						<img class="help-imgs" alt="" :src="imgs[18]" @click="onprevImg(18)">
					</p>
					<h7 class="small-title">
						<b class="small-title-word word-mark" style="width: 90px;text-indent: 66px;top:-3px;" />
						<p class="small-title-desc" style="padding-left: 80px;">如希望在企微应用内，设置某个菜单作为登录入口，则设置如下：找到【功能】-【自定义菜单】项，点开并设置菜单项，粘贴全员营销地址到菜单项内，即完成设置；</p>
					</h7>
					<p class="mod-img">
						<img class="help-imgs" alt="" :src="imgs[19]" @click="onprevImg(19)">
					</p>
					<p class="mod-img" style="margin-top:20px">
						<img class="help-imgs" alt="" :src="imgs[20]" @click="onprevImg(20)">
					</p>
				</div>
			</div>
		</div>
		<jw-img-view :imgs="imgs" :visible.sync="visible" :select.sync="select" />
	</div>
</template>

<script>
var throttle = function (func, delay) {
  var timer = null
  return function () {
    var context = this
    var args = arguments
    if (!timer) {
      timer = setTimeout(function () {
        func.apply(context, args)
        timer = null
      }, delay)
    }
  }
}

export default {
  data () {
    return {
      levelTwoIsCollapsed: false,
      visible: false,
      select: 0,
      focusIndex: null,
      imgs: [
        'https://xmf-bos.jwyun.net/hnjing-xmf/3d3cabb2c3e522a063c4e70ab2157568.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/48806ad6baa35dbc30459e16c4143fff.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/e332570090ec461934588500b4c1a25a.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/126ed9140d55309273e9f65e861db590.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/449e92fcea97348b5850fd090bc19dce.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/a92ceca81115cf5f836d210909b44d89.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/a58f2396666ddbed2f05c8438b7a9937.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/f18b88d041a427083fb5ee4dc573ba44.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/b40d5a69c4a8f2d66dea819ccc01d7fe.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/c9ec92518a1f7a77ffc97e3d88b026e9.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/1371ff50ff4fdd9822d4160d5e137e52.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/b53fa17912bebc9d64b0b892992bedc4.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/902a6eb79664061f1f155bf071b09602.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/6f92f5114aa9f1711cf5f1a4fa366044.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/31b4bd07be2d6dd47fc90fd5c0ebbfa2.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/8837e1feeed84809fc6c46115cae7a12.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/b536568e6e9201a01e5331871c13369d.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/6687897351dc909cdba0278a6adae8fa.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/be1d458089d1d8e6af2fabc420a9d047.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/e95d4868e5c3603a0c2c1beadebcdf98.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/4f053c9b64d5a47fa737e6fa248c22a5.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100'
      ]
    }
  },
  mounted () {
    this.$el.querySelector('.doc-scroll').addEventListener('scroll', this.onscrollSection)
  },
  beforeDestroy () {
    this.$el.querySelector('.doc-scroll').removeEventListener('scroll', this.onscrollSection)
  },
  methods: {
    onscrollSection: throttle(function (e) {
      const indexs = [20, 464, 1866, 3150, 3200, 4216]
      const height = e.target.scrollTop
      for (let i = 0; i < indexs.length; i++) {
        if ((i === (indexs.length - 1) && height > indexs[indexs.length - 1]) || (height > indexs[i] && height < indexs[i + 1])) {
          this.focusIndex = i
          break
        }
      }
    }, 200),
    onprevImg (index) {
      this.select = index
      this.visible = true
    },
    onSwtichTwoTable () {
      this.levelTwoIsCollapsed = !this.levelTwoIsCollapsed
    },
    onScrollToRef (ref) {
      this.$el.querySelector('.doc-scroll').scrollTo(0, this.$refs[ref].offsetTop)
      console.log(this.$refs[ref].offsetTop)
      setTimeout(() => {
        this.$refs[ref].className = [this.$refs[ref].className, 'is-focus'].join(' ')
        setTimeout(() => {
          this.$refs[ref].className = this.$refs[ref].className.split(' ').filter(item => item !== 'is-focus').join(' ')
        }, 600)
      }, 700)
    }
  }
}
</script>

<style lang="less">
.doc-container{
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  overflow: hidden;
  min-width: 1280px;
  .doc-header{
    white-space: nowrap;
    width: 100%;
    position: relative;
    background: linear-gradient(180deg,#fdfdfd,#fff);
    z-index: 110;
    height: 50px;
    box-shadow: 0 2px 3px 0 rgba(100,100,100,0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    border-bottom: 1px solid #fff;
    .title{
      display: inline-block;
      vertical-align: middle;
      pointer-events: none;
      height: 50px;
      line-height: 24px;
      margin-left: 240px;
      box-shadow: none;
      background: inherit;
      max-width: 656px;
      padding-bottom: 4px;
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 50px;
    }
  }
  .doc-scroll{
    height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 15px;
    scroll-behavior: smooth;
    .doc-main{
      margin: 0 auto;
      width: 816px;
      min-height: 800px;
      border: none;
      font-family: -apple-system,BlinkMacSystemFont,PingFang SC,Apple Color Emoji,Helvetica,Tahoma,Arial,Hiragino Sans GB,Microsoft YaHei,sans-serif;
      -webkit-font-smoothing: antialiased;
      height: auto!important;
      background: #fff;
      box-shadow: 0 1px 5px #ddd;
      .doc-title{
        box-sizing: content-box;
        margin: 0 100px;
        width: auto;
        font-size: 30px;
        height: 60px;
        line-height: 40px;
        padding-top: 90px;
        border-bottom: 1px solid #e8ecf1;
        font-family: -apple-system,BlinkMacSystemFont,PingFang SC,Apple Color Emoji,Helvetica,Tahoma,Arial,Hiragino Sans GB,Microsoft YaHei,sans-serif;
      }
      .doc-document{
        position: relative;
        padding: 20px 100px 100px;
        font-size: 11pt;
        line-height: 1.7;
        color: #494949;
        outline: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 1172px;
        box-sizing: border-box;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
        counter-reset: chapter section subsec;
        .main-title{
          font-size: 16pt;
          line-height: 36px;
          color: #333;
          margin:0;
          padding-bottom: 8px;
          &.is-focus{
            background-color: rgba(245,212,122,.7);
          }
        }
        .sub-title {
          line-height: 28px;
          font-size: 18px;
          font-weight: bold;
          color: #333;
          text-indent: 10px;
          margin: 0;
          position: relative;
          &.is-focus{
            background-color: rgba(245,212,122,.7);
          }
          &.blod{
            font-weight: bold;
            .small-title-word{
              font-weight: bold;
            }
          }
          .small-title-word{
            font-style: normal;
            font-weight: normal;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 50px;
            text-indent: 0px;
            z-index: 1;
            &.word-mark::after{
              // content: "\F104";
              content: '';
              background: #494949;
              display: inline-block;
              width: 4px;
              height: 4px;
              border: 1px solid #494949;;
            }
          }
          .small-title-desc{
            box-sizing: border-box;
            padding-left: 16px;
            &.is-focus{
              background-color: rgba(245,212,122,.7);
            }
            a{
              color: #70b1e7!important;
              cursor: pointer!important;
              text-decoration: none;
              word-break: break-all;
              a:hover{
                color: #1787cf!important;
              }
            }
          }
        }
        .small-title{
          position: relative;
          line-height: 26px;
          &.is-focus{
            background-color: rgba(245,212,122,.7);
          }
          &.blod{
            font-weight: bold;
            .small-title-word{
              font-weight: bold;
            }
          }
          .small-title-word{
            font-style: normal;
            font-weight: normal;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 50px;
            text-indent: 10px;
            z-index: 1;
            &.word-mark::after{
              // content: "\F104";
              content: '';
              background: #fff;
              display: inline-block;
              width: 4px;
              height: 4px;
              border: 1px solid #494949;;
            }
          }
          .small-title-desc{
            box-sizing: border-box;
            padding-left: 26px;
            &.is-focus{
              background-color: rgba(245,212,122,.7);
            }
            a{
              color: #70b1e7!important;
              cursor: pointer!important;
              text-decoration: none;
              word-break: break-all;
              a:hover{
                color: #1787cf!important;
              }
            }
          }
        }
        .mod-img {
          margin: 0;
          padding: 0;
          .help-imgs{
            max-width: 100%;
            display: block;
            opacity: 1;
            transition: opacity .5s ease-out;
            transform-origin: left top;
            max-width: 100%;
            outline: none;
            outline-offset: inherit;
            border: none;
            box-shadow: none;
            cursor: zoom-in;
          }
        }
        .mod-tips {
          margin:0;
          padding:0;
          a{
            color: #70b1e7!important;
            cursor: pointer!important;
            text-decoration: none;
            word-break: break-all;
            padding: 0 2px;
            a:hover{
              color: #1787cf!important;
            }
          }
          img{
            display: inline-block;
            vertical-align: text-bottom;
            margin-left: 14px;
            margin-top: 10px;
          }
        }
      }
      .doc-meau{
        position: fixed;
        box-sizing: border-box;
        top: 65px;
        height: 480px;
        width: 315px;
        margin-left: -315px;
        .doc-meau-contnet{
          position: absolute;
          width: 100%;
          min-width: 171px;
          font-size: 12px;
          box-sizing: border-box;
          padding: 0 20px 0 0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          .doc-meau-directory{
            position: relative;
            visibility: visible;
            opacity: 1;
            width: 100%;
            height: 100%;
            text-align: left;
            color: #ccc;
            margin: 20px 0;
            padding: 0;
            overflow: hidden;
            transition: visibility .3s,opacity .3s ease-in-out;
            .doc-directory-title{
              position: relative;
              z-index: 2;
              background: #f7f7f7;
              color: #a5a5a5;
              border-bottom: 1px solid #ddd;
              padding-bottom: 10px;
              margin-left: 15px;
            }
            .doc-directory-container{
              max-height: 100%;
              box-sizing: border-box;
              overflow: hidden;
              padding-right: 7px;
              transition: transform .25s ease-in-out;
              transform: translateY(0);
              -ms-touch-action: auto;
              touch-action: auto;
              .doc-directory-list{
                height: auto;
                padding: 10px 0;
                list-style: none;
                margin: 0;
                li.toc-entry{
                  line-height: 18px;
                  border: none;
                  cursor: pointer;
                  padding: 6px 0 6px 15px;
                  color: #666;
                  &::before{
                    content: '';
                  }
                  &:hover{
                    color: #5e97d4;
                    .icon-collapsed{
                      display: block;
                    }
                  }
                  &.is-active{
                    color: #5e97d4;
                  }
                  &.heading-2{
                    margin-left: 15px;
                    padding-left: 10pt;
                  }
                  &.heading-3{
                    margin-left: 15px;
                    padding-left: 20pt;
                  }
                  &.is-collapsed .icon-collapsed {
                    display: block;
                    svg{
                      transform: rotate(0deg);
                    }
                  }
                  .icon-collapsed{
                    width: 15px;
                    height: 18px;
                    position: absolute;
                    padding-right: 2px;
                    padding-left: 3px;
                    transform: translateX(-100%);
                    display: none;
                    svg{
                      width: 10px;
                      height: 10px;
                      transform: rotate(90deg);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
